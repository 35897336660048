<div class="container">
  <div class="row">
    <div class="col-md-8 mb-2">
      <section class="order-update">
        <span class="text-fixed-medium">{{
          'addToCartItems.temporderUpdated' | cxTranslate
        }}</span>
      </section>
    </div>
    <div
      class="col-md-4 text-right d-flex flex-wrap gap-1 justify-content-md-end"
    >
      <ng-template #popSaveTitle>{{
        'addToCartItems.saveOrderTitle' | cxTranslate
      }}</ng-template>
      <ng-template #popSaveContent>
        <input
          type="text"
          class="save-order"
          required
          [(ngModel)]="saveCartName"
          maxlength="30"
          (keypress)="omit_special_char($event)"
        />
        <span class="common-error required">{{ errorMsg }}</span>
        <button
          type="reset"
          class="popover-close btn white-button popover-cancel-button"
          (click)="toggle(p2)"
        >
          {{ 'addToCartItems.cancel' | cxTranslate }}
        </button>
        <button
          class="btn blue-button popover-cancel-button"
          type="submit"
          data-di-id="di-id-3064f17d-e97cc628"
          (click)="saveOrder()"
        >
          {{ 'addToCartItems.save' | cxTranslate }}
        </button>
      </ng-template>
      <a
        class="c-link"
        [ngbPopover]="popSaveContent"
        [popoverTitle]="popSaveTitle"
        popoverClass="popover-links"
        triggers="manual"
        placement="top"
        container="body"
        #p2="ngbPopover"
        href="javascript:;"
        (click)="toggle(p2)"
      >
        <span>{{ 'addToCartItems.saveOrder' | cxTranslate }}</span>
        <fa-icon [icon]="faRectangleList" class="icon"></fa-icon>
      </a>
      <a
        id="tealium-xls"
        data-di-id="#tealium-xls"
        aria-label="download as"
        class="c-link"
        href="javascript:void(0)"
        target=""
        (click)="exportXls()"
      >
        <span>{{ 'addToCartItems.xls' | cxTranslate }}</span>
        <fa-icon [icon]="faFileExcel" class="icon"></fa-icon>
      </a>
      <a
        id="printLinkId"
        data-di-id="#printLinkId"
        class="c-link"
        href="javascript:void(0)"
        target=""
        onclick="window.print();"
      >
        <span>{{ 'addToCartItems.print' | cxTranslate }}</span>
        <fa-icon [icon]="faPrint" class="icon"></fa-icon>
      </a>
    </div>
  </div>
</div>
<section class="container">
  <div class="row mb-4 mt-4">
    <!-- Main content -->
    <section class="col-md-8 u-border-right-grey pr-md-4 pr-lg-5">
      <!-- Header -->
      <section class="row py-4 u-border-bottom-grey">
        <div class="col-md-8 mb-2">
          <ng-template #deliveryLoc>
            <i class="fa fa-map-marker-alt font-n"></i>
            <span class="px-3 text-fixed-medium"
              >{{ orderConfirmationDetails?.shippingAddress?.companyName }}
              {{
                '(' + orderConfirmationDetails?.shippingAddress?.unitID + ')'
              }}</span
            >
          </ng-template>
          <span
            *ngIf="
              orderConfirmationDetails?.deliveryMode?.code === 'pickup';
              else deliveryLoc
            "
            class="pickupLocation"
          >
            <h6>{{ 'addToCartItems.pickLocation' | cxTranslate }}</h6>
            <p>
              {{ 'addToCartItems.sanofiName' | cxTranslate }} <br />{{
                'addToCartItems.locationLine1' | cxTranslate
              }}<br />{{ 'addToCartItems.locationLine2' | cxTranslate }}
            </p>
          </span>
        </div>
        <!-- Header -->
        <div class="col-md-4 d-md-flex justify-content-between">
          <span class="font-bold"
            >{{ 'addToCartItems.po' | cxTranslate }}
            {{ 'addToCartItems.orderspclchar' | cxTranslate }}&nbsp;
          </span>
          <span>{{ poNumber }}</span>
        </div>
      </section>
      <!-- Info -->
      <section class="row mt-4">
        <section class="col-md-12">
          <section class="row pb-4">
            <!-- sold to -->
            <section class="col-md-8">
              <h3 class="font-bold">
                {{ 'addToCartItems.soldTo' | cxTranslate }}
              </h3>
              <p class="text-fixed-medium">
                #{{ orderConfirmationDetails?.soldtoAddress?.unitID }}
                <br>{{ orderConfirmationDetails?.soldtoAddress?.companyName }}
              </p>
            </section>
            <!-- Total -->
            <section class="col-md-4 text-fixed-medium">
              <section class="row">
                <section class="col-6">
                  <span>{{ 'addToCartItems.subtotal' | cxTranslate }}</span>
                </section>
                <section class="col-6 text-right">
                  <span>
                    {{ orderConfirmationDetails?.subTotal?.value | currency }}
                  </span>
                </section>
              </section>
              <section class="row u-border-bottom-black py-1">
                <section class="col-6">
                  <span class="">{{
                    'addToCartItems.salesTax' | cxTranslate
                  }}</span>
                </section>
                <section class="col-6 text-right">
                  <span>
                    {{ orderConfirmationDetails?.totalTax?.value | currency }}
                  </span>
                </section>
              </section>
              <section class="row font-bold py-1">
                <section class="col-6">
                  <span>{{ 'addToCartItems.total' | cxTranslate }}</span>
                </section>
                <section class="col-6 text-right">
                  <span>
                    {{
                      orderConfirmationDetails?.totalPriceWithTax?.value
                        | currency
                    }}
                  </span>
                </section>
              </section>
            </section>
          </section>

          <section class="row">
            <!-- Ship to -->
            <ng-container
              *ngIf="orderConfirmationDetails?.pickupDate === undefined"
            >
              <section class="col-md-12">
                <h3 class="font-bold">
                  {{ 'addToCartItems.shipTo' | cxTranslate }}
                </h3>
              </section>

              <section class="col-md-12 text-fixed-medium pb-4">
                <p class="mb-0">
                  <span>{{
                    orderConfirmationDetails?.shippingAddress?.companyName
                      ? orderConfirmationDetails?.shippingAddress?.companyName
                      : ''
                  }}</span>
                  <br />
                  <span
                    >{{
                      orderConfirmationDetails?.shippingAddress?.line1
                        ? orderConfirmationDetails?.shippingAddress?.line1
                        : ''
                    }}
                    ,
                    {{
                      orderConfirmationDetails?.shippingAddress?.line2
                        ? orderConfirmationDetails?.shippingAddress?.line2
                        : ''
                    }}</span
                  >
                  <br />
                  <span>
                    {{
                      orderConfirmationDetails?.deliveryAddress?.town
                        ? orderConfirmationDetails?.deliveryAddress?.town
                        : ''
                    }}
                    ,
                    {{
                      orderConfirmationDetails?.shippingAddress?.region
                        ?.isocodeShort
                        ? orderConfirmationDetails?.shippingAddress?.region
                            ?.isocodeShort
                        : ''
                    }}
                    {{
                      orderConfirmationDetails?.deliveryAddress?.postalCode
                        ? orderConfirmationDetails?.shippingAddress?.postalCode
                        : ''
                    }}</span
                  >
                </p>
              </section>
            </ng-container>
            <!-- Order date -->
            <section class="col-md-12">
              <h3 class="font-bold">
                {{ 'addToCartItems.orderDate' | cxTranslate }}
              </h3>
              <p class="text-fixed-medium">
                {{
                  orderConfirmationDetails?.created
                    | slice : 0 : 10
                    | date : dateFormat
                }}
              </p>
            </section>
            <!-- Requested delivery date -->
            <section
              class="col-md-12"
              *ngIf="
                orderConfirmationDetails?.deliveryMode?.code === 'pickup';
                else deliveryDate
              "
            >
              <h3 class="font-bold">
                {{ 'addToCartItems.pickupDate' | cxTranslate }}
              </h3>
              <p class="text-fixed-medium">
                {{ orderConfirmationDetails?.pickupDate }}
              </p>
            </section>
            <ng-template #deliveryDate>
              <section class="col-md-12">
                <h3 class="font-bold">
                  {{ 'addToCartItems.deliveryDate' | cxTranslate }}
                </h3>
                <p class="text-fixed-medium">
                  {{ estimatedDate | slice : 0 : 10 | date : dateFormat }}
                </p>
              </section>
            </ng-template>
          </section>
        </section>
      </section>
      <!-- Products -->
      <div
        class="row u-border-top-grey py-4"
        *ngFor="let productEntry of productEntries"
      >
        <div class="col-md-2 mb-4">
          <a (click)="productDetails(productEntry)">
            <img
              class="img"
              *ngIf="productEntry?.PRIMARY !== undefined"
              src="{{ productEntry?.PRIMARY?.url }}"
              alt=""
            />
            <img
              class="img"
              *ngIf="productEntry?.PRIMARY === undefined"
              [src]="fallbackImage"
              alt="Product Image"
            />
          </a>
        </div>
        <div class="col-md-10">
          <div class="row c-prod-details">
            <div class="col-md-12">
              <a (click)="productDetails(productEntry)">
                <h2
                  class="font-bold c-heading c-heading-h4 mb-4"
                  [innerHTML]="productEntry?.product?.name"
                ></h2>
              </a>
            </div>

            <div
              class="col-md-12 d-md-flex justify-content-between text-fixed-medium"
            >
              <div>
                <span class="font-bold">{{ 'plp.units' | cxTranslate }}</span>
                <span class="d-inline-block d-sm-none"
                  >:&nbsp;{{ productEntry?.quantity }}</span
                >
                <span class="d-none d-md-block">{{
                  productEntry?.quantity
                }}</span>
              </div>
              <div>
                <span
                  class="font-bold"
                  *ngIf="
                    productEntry?.product?.actualPotency;
                    else notActualPotency
                  "
                >
                  {{ 'plp.your_price' | cxTranslate }}
                </span>
                <ng-template #notActualPotency>
                  <span class="font-bold">{{
                    'plp.yourPrice' | cxTranslate
                  }}</span>
                </ng-template>
                <span class="d-inline-block d-sm-none"
                  >:&nbsp;{{ productEntry?.basePrice?.value | currency }}</span
                >
                <span class="d-none d-md-block">{{
                  productEntry?.basePrice?.value | currency
                }}</span>
              </div>
              <div>
                <span class="font-bold">{{
                  'addToCartItems.subtotal' | cxTranslate
                }}</span>
                <span class="d-inline-block d-sm-none"
                  >:&nbsp;{{ productEntry?.totalPrice?.value | currency }}</span
                >
                <span class="d-none d-md-block">{{
                  productEntry?.totalPrice?.value | currency
                }}</span>
              </div>
              <div>
                <span class="font-bold">{{
                  'addToCartItems.status' | cxTranslate
                }}</span>
                <span class="d-inline-block d-sm-none"
                  >:&nbsp;{{ 'addToCartItems.statusOpen' | cxTranslate }}</span
                >
                <span class="d-none d-md-block">{{
                  'addToCartItems.statusOpen' | cxTranslate
                }}</span>
              </div>
              <div>
                <span class="font-bold">{{
                  'addToCartItems.estDeliveryDate' | cxTranslate
                }}</span>
                <span class="d-inline-block d-sm-none"
                  >:&nbsp;{{
                    productEntry?.estimatedDeliveryDate
                      | slice : 0 : 10
                      | date : dateFormat
                  }}</span
                >
                <span class="d-none d-md-block">{{
                  productEntry?.estimatedDeliveryDate
                    | slice : 0 : 10
                    | date : dateFormat
                }}</span>
              </div>
            </div>
            <section class="col-md-12 text-fixed-medium py-4">
              <div
                class="m-0"
                [innerHTML]="productEntry?.product?.description"
              ></div>
            </section>
            <section class="col-md-12 text-fixed-medium">
              <span class="font-bold"
                >{{ 'plp.productNumber' | cxTranslate }}:</span
              >
              {{ productEntry?.product?.sapEan }}<br />
              <div *ngIf="productEntry?.product?.actualPotency">
                <span class="font-bold"
                  >{{ 'plp.actualPotency' | cxTranslate }}:</span
                >
                {{ productEntry?.product?.actualPotency }}<br />
                <span class="font-bold"
                  >{{ 'plp.totalPotency' | cxTranslate }}:</span
                >
                {{
                  productEntry?.product?.actualPotency * productEntry?.quantity
                }}
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>

    <!-- Sidebar -->
    <aside class="col-md-4 pl-md-4 pl-lg-5">
      <!-- Order total -->
      <section class="row">
        <section
          class="col-md-12 col-lg-12 col-xl-12 py-4 u-border-top-grey d-flex align-items-center justify-content-center justify-content-md-between flex-column flex-md-row"
        >
          <h3 class="font-bold">
            {{ 'addToCartItems.orderTotal' | cxTranslate }}:
          </h3>
          <h1 class="font-bold">
            {{ orderConfirmationDetails?.totalPriceWithTax?.value | currency }}
          </h1>
        </section>
      </section>
      <!-- Payment method -->
      <section class="row u-border-bottom-grey">
        <section class="col-md-12 col-lg-12 col-xl-12">
          <h3 class="font-bold mb-4">
            {{ 'addToCartItems.paymentMethod' | cxTranslate }}
          </h3>
          <p class="font-bold">
            {{ 'orderConfirmation.invoiceLater' | cxTranslate }}
          </p>
          <p class="text-fixed-medium">
            {{ 'addToCartItems.paymentNote' | cxTranslate }}
          </p>
        </section>
      </section>
      <!-- Order contact -->
      <section class="row mb-2 pt-4">
        <section class="col-md-12">
          <h3 class="font-bold">
            {{ 'addToCartItems.orderContact' | cxTranslate }}
          </h3>
        </section>
      </section>
      <section class="row mb-4">
        <section class="col-md-3 mb-2">
          <span class="font-bold">{{
            'addToCartItems.name' | cxTranslate
          }}</span>
        </section>
        <section class="col-md-9">
          <span>{{ orderConfirmationDetails?.orderContact?.name }}</span>
        </section>
        <section class="col-md-3 mb-2">
          <span class="font-bold">{{
            'addToCartItems.phone' | cxTranslate
          }}</span>
        </section>
        <section class="col-md-9">
          <span>{{
            orderConfirmationDetails?.orderContact?.phoneNo
              ? orderConfirmationDetails?.orderContact?.phoneNo
              : ''
          }}</span>
        </section>
        <section
          class="col-md-3 mb-2"
          *ngIf="orderConfirmationDetails?.orderContact?.mobileNo"
        >
          <span class="font-bold">{{
            'addToCartItems.mobile' | cxTranslate
          }}</span>
        </section>
        <section
          class="col-md-9"
          *ngIf="orderConfirmationDetails?.orderContact?.mobileNo"
        >
          <span>{{ orderConfirmationDetails?.orderContact?.mobileNo }}</span>
        </section>
        <section class="col-md-3 mb-2">
          <span class="font-bold">{{
            'addToCartItems.email' | cxTranslate
          }}</span>
        </section>
        <section class="col-md-9">
          <a
            class="c-force-wrap"
            href="{{
              'mailto:' + orderConfirmationDetails?.orderContact?.email
            }}"
            class="email-Value"
            >{{ orderConfirmationDetails?.orderContact?.email }}</a
          >
        </section>
      </section>
      <!-- Billing Address -->
      <section class="row text-fixed-medium">
        <section class="col-lg-6 mb-4">
          <section class="row">
            <section class="col-md-12">
              <h4 class="font-bold">
                {{ 'addToCartItems.billingAddress' | cxTranslate }}
              </h4>
            </section>
            <section class="col-md-12">
              <span
                >{{ 'addToCartItems.accountNo' | cxTranslate }}:
                {{
                  orderConfirmationDetails?.billingAddress?.unitID
                    ? orderConfirmationDetails?.billingAddress?.unitID
                    : ''
                }}</span
              >
            </section>
            <section class="col-md-12">
              <span>{{
                orderConfirmationDetails?.billingAddress?.companyName
                  ? orderConfirmationDetails?.billingAddress?.companyName
                  : ''
              }}</span>
            </section>
            <section class="col-md-12">
              <span
                >{{
                  orderConfirmationDetails?.billingAddress?.line1
                    ? orderConfirmationDetails?.billingAddress?.line1
                    : ''
                }},
                {{
                  orderConfirmationDetails?.billingAddress?.line2
                    ? orderConfirmationDetails?.billingAddress?.line2
                    : ''
                }}</span
              >
            </section>
            <section class="col-md-12">
              <span
                >{{
                  orderConfirmationDetails?.billingAddress?.town
                    ? orderConfirmationDetails?.billingAddress?.town
                    : ''
                }},
                {{
                  orderConfirmationDetails?.billingAddress?.region?.isocodeShort
                    ? orderConfirmationDetails?.billingAddress?.region
                        ?.isocodeShort
                    : ''
                }}
                {{
                  orderConfirmationDetails?.billingAddress?.postalCode
                    ? orderConfirmationDetails?.billingAddress?.postalCode
                    : ''
                }}</span
              >
            </section>
          </section>
        </section>
        <section class="col-lg-6 mb-4">
          <section class="row">
            <section class="col-md-12">
              <h4 class="font-bold">
                {{ 'addToCartItems.soldToAddress' | cxTranslate }}
              </h4>
            </section>
            <section class="col-md-12">
              <span
                >{{ 'addToCartItems.accountNo' | cxTranslate }}:
                {{
                  orderConfirmationDetails?.soldtoAddress?.unitID
                    ? orderConfirmationDetails?.soldtoAddress?.unitID
                    : ''
                }}</span
              >
            </section>
            <section class="col-md-12">
              <span>{{
                orderConfirmationDetails?.soldtoAddress?.companyName
                  ? orderConfirmationDetails?.soldtoAddress?.companyName
                  : ''
              }}</span>
            </section>
            <section class="col-md-12">
              <span
                >{{
                  orderConfirmationDetails?.soldtoAddress?.line1
                    ? orderConfirmationDetails?.soldtoAddress?.line1
                    : ''
                }},
                {{
                  orderConfirmationDetails?.soldtoAddress?.line2
                    ? orderConfirmationDetails?.soldtoAddress?.line2
                    : ''
                }}</span
              >
            </section>
            <section class="col-md-12">
              <span
                >{{
                  orderConfirmationDetails?.soldtoAddress?.town
                    ? orderConfirmationDetails?.soldtoAddress?.town
                    : ''
                }},
                {{
                  orderConfirmationDetails?.soldtoAddress?.region?.isocodeShort
                    ? orderConfirmationDetails?.soldtoAddress?.region
                        ?.isocodeShort
                    : ''
                }}
                {{
                  orderConfirmationDetails?.soldtoAddress?.postalCode
                    ? orderConfirmationDetails?.soldtoAddress?.postalCode
                    : ''
                }}</span
              >
            </section>
          </section>
        </section>
      </section>
      <section class="row">
        <section class="col-md-12">
          <a href="javascript:;" class="c-link" (click)="billingAccount()">
            <span class="manage-Billing-info">{{
              'addToCartItems.billingInfo' | cxTranslate
            }}</span>
          </a>
        </section>
      </section>
    </aside>
  </div>
</section>
